.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px;
    font-family: Arial, sans-serif;
    background-color: #f2f2f2;
  }
  
  .privacy-policy-title {
    font-size: 32px;
    font-weight: bold;
    color: #0077b6;
    margin-bottom: 20px;
  }
  
  .privacy-policy-paragraph {
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    margin-bottom: 20px;
  }
  
  .privacy-policy-subtitle {
    font-size: 24px;
    font-weight: bold;
    color: #0077b6;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .privacy-policy-list {
    font-size: 16px;
    line-height: 1.5;
    list-style-type: none;
    padding-left: 0;
    color: #333;
  }
  
  .privacy-policy-list-item {
    margin-bottom: 10px;
  }
  
  .privacy-policy-list-item strong {
    font-weight: bold;
  }