.date-card {
    background-color: #f0f4f8;
    border: 1px solid #e0e6ed;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1rem;
  }
  
  .text-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: #495057;
  }
  
  .text-sm {
    font-size: 1rem;
    font-weight: 500;
    color: #495057;
  }
  
  .btn {
    min-width: 100px;
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    white-space: nowrap;
    text-align: center;
  }
  
  .d-flex.flex-wrap {
    gap: 0.5rem;
    justify-content: space-between;
  }
  
  .date-card h5 {
    color: #6c757d;
  }
  
  .p-2 {
    flex: 1 1 auto;
  }
  