.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .upload-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  .upload-button .icon {
    font-size: 1.5rem;
  }
  
  .upload-input {
    display: none;
  }
  
  .file-info {
    margin-top: 0.5rem;
    font-size: 0.875rem;
    color: #6c757d;
  }