.doctor-profile-card {
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .doctor-profile-avatar {
    text-align: center;
  }
  
  .doctor-name {
    margin-top: 1rem;
    font-weight: bold;
    color: #0077b6;
  }
  
  .doctor-degree {
    color: #666;
    font-size: 1.1rem;
  }
  
  .doctor-info-label {
    font-weight: bold;
    color: #0077b6;
    font-size: 1.1rem;
  }
  
  .doctor-info-value {
    font-size: 1.1rem;
    color: #333;
  }
  
  .doctor-divider {
    border-color: #ddd;
    margin: 2rem 0;
  }
  
  .doctor-section-title {
    color: #0077b6;
    font-weight: bold;
  }
  
  .doctor-biography,
  .doctor-education,
  .doctor-services {
    color: #333;
    font-size: 1.1rem;
  }