.page-header {
	margin-bottom: 1.875rem;
}

.page-title {
    color: #333;
    margin-bottom: 5px;
}
.main-wrapper {
	width: 100%;
	height: 100vh;
	min-height: 100vh;
}
.page-wrapper {
	margin-left: 240px;
	padding-top: 60px;
	position: relative;
	transition: all 0.4s ease;
}
.page-wrapper > .content {
	padding: 1.875rem 1.875rem 0;
}
.page-header {
	margin-bottom: 1.875rem;
}
.page-title {
    color: #333;
    margin-bottom: 5px;
}