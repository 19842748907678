.user-type-selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.user-type-selection-content {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
  padding: 3rem;
  text-align: center;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  width: 90%;
}

.user-type-selection-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.user-type-selection-subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 2rem;
}

.user-type-selection-options {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.user-type-option {
  background-color: #fff;
  border-radius: 15px;
  padding: 2rem;
  width: 300px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.user-type-option:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
}

.user-type-image {
  width: 150px;
  height: 150px;
  margin: 0 auto 1.5rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.doctor-image {
  background-image: url('../../images/doc.svg');
}

.patient-image {
  background-image: url('../../images/patient.svg');
}

.user-type-option h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.user-type-option p {
  font-size: 1rem;
  color: #666;
}

@media (max-width: 768px) {
  .user-type-selection-options {
    flex-direction: column;
    align-items: center;
  }

  .user-type-option {
    width: 100%;
    max-width: 300px;
  }
}