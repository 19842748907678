.symptoms-section h5{
    font-size: 14px;
    font-weight: 700;
}

.symptoms-section p{
    font-size: 13px;
    font-weight: 500;
    color: #3f3f3f;
}

.feedback{
    margin: 5px;
}