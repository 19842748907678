.track-detail {
    padding: 30px 0;
    margin-bottom: 8rem;
    background-color: #f8f9fa;
}
.img-div{
    width: 50px;
    height: 50px;
    overflow: hidden;
}
.track-detail img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 3px solid #c2c2c2;
}

/* Time Line Style */
.timeline {
    list-style: none;
    padding: 20px;
    position: relative;
}

.timeline:before {
    position: absolute;
    content: " ";
    background-color: #eeeeee;
}

.timeline-item {
    margin-bottom: 20px;
    position: relative;
}

.timeline-badge {
    width: 20px;
    height: 20px;
    position: absolute;
    border: 3px solid #b3b0b0;
    border-radius: 50%;
}

.timeline-panel:before {
    position: absolute;
    display: inline-block;
    content: " ";
}

.timeline-horizontal:before {
    height: 3px;
    top: auto;
    bottom: 26px;
    left: 56px;
    right: 0;
    width: 100%;
}

.timeline-horizontal .timeline-item {
    display: table-cell;
    height: 180px;
    width: 20%;
    min-width: 250px;
    float: none !important;
    padding-left: 0;
    padding-right: 20px;
    margin: 0 auto;
    vertical-align: bottom;
}

.timeline-horizontal .timeline-panel {
    bottom: 50px;
    display: inline-block;
    &:before {
        top: auto;
        bottom: -16px;
        left: 28px !important;
        right: auto;
        border-right: 16px solid transparent !important;
        border-top: 16px solid #b3b0b0 !important;
        border-bottom: 0 solid #b3b0b0 !important;
        border-left: 16px solid transparent !important;
    }
}
.timeline-horizontal .timeline-badge {
    top: auto;
    bottom: 0;
    left: 37px;
}
.trackpage ::-webkit-scrollbar {
    width: 10px;
  }

  .time-line-section{
    display: inline-block;
    width: 100%;
    overflow-y: auto;
  }


  .time-line-section::-webkit-scrollbar {
    width: 20px;
  }
  
  .time-line-section::-webkit-scrollbar-track {
    background-color: #ebeaea;
    border-radius: 100px;
  }
  
  .time-line-section::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border: 5px solid transparent;
    background-clip: content-box;
    background-color: #4588c4;
  }