
/* evenst */

body .calendar_default_event_inner {
    background: #a3c9f1;
    color: #000;
    border: 1px solid #2e78d6;
    border-radius: 5px;
    font-size: 10pt;
    padding: 5px;
    opacity: 0.8;
}

body .month_default_event_inner {
    background: #a3c9f1;
    color: #000;
    border: 1px solid #2e78d6;
    border-radius: 5px;
    font-size: 10pt;
    padding: 5px;
    opacity: 0.8;
}

/* toolbar */

.schedule-container {
    display: flex;
    flex-direction: row;
}

.schedule-navigator {
    margin-right: 10px;
}

.schedule-content {
    flex-grow: 1;
}

/* toolbar */
.schedule-toolbar {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.schedule-toolbar-group {
    display: inline-flex;
}

.schedule-toolbar button {
    background-color: #3c78d8;
    color: white;
    border: 0;
    padding: .5rem 1rem;
    width: 80px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    margin-right: 1px;
    transition: all 0.2s;
    box-shadow: 0 4px 6px rgba(0,0,0,0.08);
    box-sizing: border-box;
}

.schedule-toolbar button.selected {
    background-color: #1c4587;
    box-shadow: 0 3px 5px rgba(0,0,0,0.1);
}

.schedule-toolbar .schedule-toolbar-group button:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.schedule-toolbar .schedule-toolbar-group button:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.schedule-toolbar button.standalone {
    border-radius: 30px;
    margin-left: 10px; /* Adds space between standalone and other buttons */
    margin-right: 0; /* Reset margin-right for standalone button */
}

.schedule-toolbar button:hover {
    background-color: #2f66c4;
    box-shadow: 0 5px 7px rgba(0,0,0,0.1);
}

.schedule-toolbar button:active {
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.schedule-standalone{
    margin-left: 2vw;
border-radius: 20px;
}
.schedule-standalone-go-back{
    background-color: #2f66c4;
    border-radius: 50px;
    color:  white;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 20px;
    border: none;
}