/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .landing-page {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  /* Header Styles */
  header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #007bff;
  }
  
  nav ul {
    display: flex;
    list-style-type: none;
  }
  
  nav ul li {
    margin-left: 2rem;
  }
  
  nav ul li a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: color 0.3s ease;
  }
  
  nav ul li a:hover {
    color: #007bff;
  }
  
  /* Hero Section */
  .hero {
    color: white;
    text-align: center;
    padding: 8rem 2rem 4rem;
    /* background-color: #f8f9fa; */
    background-repeat: no-repeat;
    background-position: initial;
    background-size: cover;
    background-image: url(https://images.pexels.com/photos/356040/pexels-photo-356040.jpeg);
  }
  

  .hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .hero p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .cta-button {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 0.8rem 2rem;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  
  /* Features Section */
  #features {
    padding: 4rem 2rem;
  }
  
  #features h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .feature-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
  }
  
  .feature-item {
    background-color: #fff;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }
  
  .feature-item h3 {
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  /* Pricing Section */
  #pricing {
    background-color: #f8f9fa;
    padding: 4rem 2rem;
  }
  
  #pricing h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .pricing-plans {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
  }
  
  .plan {
    background-color: #fff;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    text-align: center;
    flex: 1;
    min-width: 250px;
  }
  
  .plan h3 {
    color: #007bff;
  }
  
  .plan .price {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1rem 0;
  }
  
  .plan ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 2rem;
  }
  
  .plan ul li {
    margin-bottom: 0.5rem;
  }
  
  .plan-button {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 0.6rem 1.5rem;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .plan-button:hover {
    background-color: #0056b3;
  }
  
  .plan.featured {
    transform: scale(1.05);
    border: 2px solid #007bff;
  }
  
  /* Testimonials Section */
  #testimonials {
    padding: 4rem 2rem;
  }
  
  #testimonials h2 {
    text-align: center;
    margin-bottom: 3rem;
  }
  
  .testimonial-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .testimonial {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }
  
  .testimonial p {
    font-style: italic;
    margin-bottom: 1rem;
  }
  
  .testimonial cite {
    font-weight: bold;
  }
  
  /* Footer Styles */
  footer {
    background-color: #333;
    color: #fff;
    padding: 3rem 2rem;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .footer-section {
    flex: 1;
    min-width: 200px;
  }
  
  .footer-section h4 {
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .footer-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 0.5rem;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .footer-section ul li a:hover {
    color: #007bff;
  }
  
  .copyright {
    text-align: center;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    nav {
      flex-direction: column;
    }
  
    nav ul {
      margin-top: 1rem;
    }
  
    nav ul li {
      margin-left: 0;
      margin-right: 1rem;
    }
  
    .hero h1 {
      font-size: 2rem;
    }
  
    .pricing-plans {
      flex-direction: column;
    }
  
    .plan {
      width: 100%;
    }
  
    .plan.featured {
      transform: none;
    }
  }