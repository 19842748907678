.booking-summary {
    background: #f8f9fa;
    padding: 1.5rem;
    border-radius: 8px;
  }
  
  .booking-doc-img img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .doc-title {
    font-size: 22px;
    font-weight: 700;
    color: #333;
  }
  
  .booking-item-wrap {
    padding-top: 1rem;
  }
  
  .booking-item-wrap ul {
    padding: 0;
    list-style: none;
    margin-bottom: 1rem;
  }
  
  .booking-item-wrap ul li {
    font-size: 16px;
    font-weight: 500;
    color: #272b41;
    text-transform: capitalize;
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
  
  .booking-total {
    border-top: 1px solid #e4e4e4;
    padding-top: 1rem;
    font-weight: 600;
    font-size: 18px;
  }
  
  .booking-total .total-cost {
    color: #1977cc;
  }
  
  .paymentForm {
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  
  .paymentForm .form-group {
    margin-bottom: 1.5rem;
  }
  
  .paymentForm label {
    font-weight: 500;
    color: #333;
    margin-bottom: 0.5rem;
    display: block;
  }
  
  .paymentInput {
    padding: 0.75rem 1rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
  }
  .faded {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #d3d3d3; 
    border: none;
    color: white;
    font-weight: 500;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
}
  
  .paymentFormBtn {
    border: none;
    background-color: #1977cc;
    color: white;
    font-weight: 500;
    padding: 0.75rem;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .paymentFormBtn:hover {
    background-color: #145a8d;
  }
  
  @media screen and (max-width: 768px) {
    .paymentForm {
      width: 90%;
    }
  
    .booking-summary {
      margin-top: 1.5rem;
    }
  }
  