.rescheduled-appointment-card {
    background: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
}

.rescheduled-appointment-card:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.patient-img img {
    border: 2px solid #007bff;
}

.icon {
    color: #007bff;
    margin-right: 8px;
}

.rescheduling-actions {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 200px;
}

.ant-picker {
    width: 100%;
}