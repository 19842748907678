.patients-info>h5 {
    color: #2c4964;
    font-weight: 500;
    cursor: pointer;
    transition: 0.6s ease-in;
}

.patients-info>h5:hover {
    color: #358fe2;
    transform: scale(1.1);
}

.patients-info .info {
    color: #757575;
}

.patients-info .info p {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 3px;
}

.patients-info .info .icon {
    font-size: 23px !important;
}

.patient-img img {
    height: 150px;
    width: 150px;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    border: 8px solid #efefef;
    transition: 0.6s ease-in;
}

.patient-img img:hover {
    transform: scale(1.1);
}
.appointment-status > p{
    font-size: 14px;
    font-weight: 600;
    color: #757575;
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;
}