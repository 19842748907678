/* Feedback.css */
.feedback-container {
    max-width: 600px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .feedback-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    text-align: center;
  }
  
  .feedback-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-label {
    font-size: 1rem;
    font-weight: 600;
    color: #34495e;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #bdc3c7;
    border-radius: 6px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    outline: none;
    border-color: #3498db;
  }
  
  .star-rating {
    display: flex;
    gap: 0.5rem;
  }
  
  .star-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #bdc3c7;
    transition: color 0.3s ease;
  }
  
  .star-btn.active {
    color: #f1c40f;
  }
  
  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .submit-btn {
    width: 100%;
    padding: 0.75rem;
    background-color: #3498db;
    color: #ffffff;
    border: none;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #2980b9;
  }
  
  .submit-btn:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
  
  .error-message {
    margin-top: 1rem;
    padding: 0.75rem;
    background-color: #ffebee;
    color: #c0392b;
    border-radius: 6px;
    font-size: 0.9rem;
  }