body .w-md-editor-text-pre > code,
body .w-md-editor-text-input, .w-md-editor-toolbar  {
  font-size: 25px !important;
  line-height: 30px !important;
}

/* src/styles/global.css */

.container {
    width: 100vw;
    margin: 0 auto;
    padding: 1rem;
  }
  
  .heading {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }
  
  .solutions-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
  
  @media (min-width: 768px) {
    .solutions-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media (min-width: 1024px) {
    .solutions-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  
  .solution-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  .solution-card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-content {
    padding: 1.5rem;
  }
  
  .solution-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  
  .solution-price {
    color: #4a5568;
    margin-bottom: 1rem;
  }
  
  .solution-date {
    font-size: 0.875rem;
    color: #718096;
    margin-bottom: 1.5rem;
  }
  
  .view-details-btn {
    background-color: #3182ce;
    color: white;
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: none;
  }
  
  .view-details-btn:hover {
    background-color: #2b6cb0;
  }
  
  /* Awesome Markdown CSS */

.markdown-body {
  font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: #2c3e50;
}

.markdown-body h1 {
  font-size: 2.5em;
  border-bottom: 2px solid #3498db;
  padding-bottom: 0.3em;
}

.markdown-body h2 {
  font-size: 2em;
  border-bottom: 1px solid #3498db;
  padding-bottom: 0.3em;
}

.markdown-body h3 {
  font-size: 1.5em;
  color: #e74c3c;
}

.markdown-body h4 {
  font-size: 1.25em;
  color: #9b59b6;
}

.markdown-body h5 {
  font-size: 1em;
  color: #f39c12;
}

.markdown-body h6 {
  font-size: 0.875em;
  color: #27ae60;
}

.markdown-body p {
  margin-top: 0;
  margin-bottom: 16px;
}

.markdown-body a {
  color: #3498db;
  text-decoration: none;
  transition: color 0.3s ease;
}

.markdown-body a:hover {
  color: #2980b9;
  text-decoration: underline;
}

.markdown-body strong {
  font-weight: 600;
  color: #e74c3c;
}

.markdown-body em {
  font-style: italic;
  color: #9b59b6;
}

.markdown-body blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: 0.25em solid #3498db;
  margin: 0 0 16px 0;
}

.markdown-body ul,
.markdown-body ol {
  padding-left: 2em;
  margin-bottom: 16px;
}

.markdown-body li {
  margin-bottom: 8px;
}

.markdown-body code {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  color: #e74c3c;
}

.markdown-body pre {
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: #f6f8fa;
  border-radius: 3px;
  margin-bottom: 16px;
}

.markdown-body pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  border: 0;
  color: #333;
}

.markdown-body img {
  max-width: 100%;
  box-sizing: content-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.markdown-body hr {
  height: 0.25em;
  padding: 0;
  margin: 24px 0;
  background-color: #e1e4e8;
  border: 0;
}

.markdown-body table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 16px;
}

.markdown-body th,
.markdown-body td {
  padding: 6px 13px;
  border: 1px solid #dfe2e5;
}

.markdown-body th {
  font-weight: 600;
  background-color: #f6f8fa;
}

.markdown-body tr:nth-child(2n) {
  background-color: #f8f9fa;
}